



import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import StepNavi from '../../../components/contents/stepNavi/StepNavi.vue';
import {GetSignupResultResponse} from '../../../../types/typescript-axios/api';

Component.registerHooks([
  'beforeRouteEnter',
]);

@Component({
  components: {
    StepNavi,
  },
})
export default class SignupFormComplete extends Vue {
  private enqueteLink: string = '';
  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  private created() {
    axios.get('/wp-json/wp/v2/pages?slug=signupresult')
    .then((response: AxiosResponse<[GetSignupResultResponse]>) => {
      const data: GetSignupResultResponse = response.data[0];
      this.enqueteLink = data.acf.enquete_link;
    });
  }

  @Emit()
  private onClickOK() {
    return window.sessionStorage.getItem('fromPath');
  }
}
